$primary: rgb(192, 168, 30);
$secondary: rgb(72, 72, 72);

@import '~bootstrap/scss/bootstrap.scss';

.pointer {
  cursor: pointer;
}

.navbar .navbar-brand {
  color: $primary;
  font-weight: 700;
  font-size: 1.8em;
  line-height: 1;
}

.navbar-nav .nav-item {
  margin: 0px 30px;
  font-weight: 500;
  font-size: 0.9em;
  &.active .nav-link {
    color: $primary !important;
  }
}

.banners.slick-slider {
  .slick-dots {
    bottom: 10px;
    button:before {
      color: $primary;
      opacity: .5;
    }
  
    .slick-active button:before {
      color: $primary;
    }
  }

  .slick-prev {
    left: 40px;
    z-index: 9999;
  }

  .slick-next {
    right: 20px;
  }
}


.page {
 .title {
   font-size: 1.5em;
   position: relative;

   &:after {
     position: absolute;
     left: 16%;
     padding: 0 20px;
     width: calc(100% - 40px);
     display: block;
     border-bottom: 1.5px solid $primary;
     content: '';
   }
 } 
}

.slick-slider {
  .slick-track {
    display: flex;
    align-items: center;
  }
}

.product-slider.slick-slider {

  .slick-next:before, .slick-prev:before {
    color: black !important;
  }

  .slick-arrow :before, .slick-arrow :after {
    color: black !important;
  }
}



@include media-breakpoint-up(sm) { 
  .dropdown:hover .dropdown-menu{
    display: block;
  }
}


.footer {
  background-color: darken($primary, 30%) !important;
  color: white;
}